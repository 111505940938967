"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectAddressStep = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var update_delivery_data_1 = require("../../../../api/calls/basket/update_delivery_data");
var address_list_1 = require("../../../../api/calls/user/address_list");
var add_user_address_1 = require("../../../../api/calls/user/add_user_address");
var basket_1 = require("../../../../reducers/slices/basket/basket");
var checkout_1 = require("../../../../reducers/slices/checkout");
var main_1 = require("../../../../reducers/slices/main");
var google_tags_1 = require("../../../../constants/google_tags");
var SelectAddressStep = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.checkoutState; }), deliveryOrCollection = _a.deliveryOrCollection, isGettingBranches = _a.isGettingBranches, currentCheckoutStep = _a.currentCheckoutStep, checkoutSteps = _a.checkoutSteps, selectedAddress = _a.selectedAddress;
    var basket = (0, react_redux_1.useSelector)(function (state) { return state.basketState; }).basket;
    var _b = (0, react_1.useState)(false), isGettingUserAddressList = _b[0], setIsGettingUserAddressList = _b[1];
    var _c = (0, react_1.useState)(false), isAddingAddress = _c[0], setIsAddingAddress = _c[1];
    var _d = (0, react_1.useState)(false), isAddingDeliveryAddress = _d[0], setIsAddingDeliveryAddress = _d[1];
    var _e = (0, react_1.useState)(false), saveAddress = _e[0], setSaveAddress = _e[1];
    var _f = (0, react_1.useState)(""), newAddressLabel = _f[0], setNewAddressLabel = _f[1];
    var _g = (0, react_1.useState)([]), addressList = _g[0], setAddressList = _g[1];
    var _h = (0, react_1.useState)(""), newAddressErrorText = _h[0], setNewAddressErrorText = _h[1];
    var _j = (0, react_1.useState)(null), selectedPlaceDetails = _j[0], setSelectedPlaceDetails = _j[1];
    (0, react_1.useEffect)(function () {
        setIsGettingUserAddressList(true);
        (0, address_list_1.address_list)().then(function (response) {
            setIsGettingUserAddressList(false);
            if (response.success) {
                setAddressList(response.content.addresses);
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while getting address list!", messages: [response.message] }));
            }
        });
    }, []);
    (0, react_1.useEffect)(function () {
        var _a, _b, _c, _d, _e, _f;
        if (selectedPlaceDetails === null || selectedPlaceDetails === void 0 ? void 0 : selectedPlaceDetails.geometry) {
            dispatch((0, checkout_1.setSelectedAddress)({
                full_address: selectedPlaceDetails.formatted_address,
                longitude: (_c = (_b = (_a = selectedPlaceDetails.geometry) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.lng) === null || _c === void 0 ? void 0 : _c.toString(),
                latitude: (_f = (_e = (_d = selectedPlaceDetails.geometry) === null || _d === void 0 ? void 0 : _d.location) === null || _e === void 0 ? void 0 : _e.lat) === null || _f === void 0 ? void 0 : _f.toString(),
            }));
        }
    }, [selectedPlaceDetails]);
    var validateAddress = function () {
        var messages = [];
        var errorCount = 0;
        if (!selectedAddress) {
            messages.push("Please select an address, or add a new address in order to continue.");
        }
        // if (!selectedBranch) {
        //   messages.push("Unfortunately, we do not deliver to this address. Please select a different address, or choose to collect.");
        // }
        if (saveAddress && !newAddressLabel) {
            errorCount++;
            setNewAddressErrorText("Please enter a label for your new address.");
        }
        if (messages.length > 0 || errorCount > 0) {
            if (messages.length > 0) {
                dispatch((0, main_1.setMessageModal)({ title: "Address selection needed!", messages: messages }));
            }
            return false;
        }
        else {
            return true;
        }
    };
    var onNext = function () {
        var nextStep = currentCheckoutStep + 1;
        if (nextStep < checkoutSteps.length) {
            dispatch((0, checkout_1.setCurrentCheckoutStep)(nextStep));
        }
    };
    var onPrevious = function () {
        var previousStep = currentCheckoutStep - 1;
        if (currentCheckoutStep > 0) {
            dispatch((0, checkout_1.setCurrentCheckoutStep)(previousStep));
        }
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        isAddingAddress ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.AddressSearchControl, { value: selectedPlaceDetails, required: true, label: "Search & select address...", size: "medium", onSelectionChange: function (addressObject) {
                    if (addressObject) {
                        dispatch((0, checkout_1.setSelectedBranch)(null));
                        dispatch((0, checkout_1.setSelectedAddress)({
                            full_address: addressObject.fullAddress,
                            longitude: addressObject.coordinates.lng.toString(),
                            latitude: addressObject.coordinates.lat.toString(),
                        }));
                        setSelectedPlaceDetails(addressObject);
                    }
                } }),
            selectedPlaceDetails && (react_1.default.createElement("div", { className: "flex flex-col rounded-lg border border-primary" },
                react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center p-2 pl-4" },
                    react_1.default.createElement("div", { className: "flex-1" }, selectedPlaceDetails.fullAddress),
                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Save this address?", value: saveAddress, onChange: function (v) {
                            setSaveAddress(v);
                        } })),
                saveAddress && (react_1.default.createElement("div", { className: "px-2 pb-2" },
                    react_1.default.createElement("div", { className: "w-1/2" },
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name this address", size: "small", errorText: newAddressErrorText, value: newAddressLabel, onChange: function (v) {
                                setNewAddressLabel(v);
                            } })))))))) : (react_1.default.createElement("div", null, addressList && addressList.length > 0 ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" }, addressList.map(function (address, index) {
            return (react_1.default.createElement("div", { key: index, className: "p-4 border border-gray-400 rounded-md cursor-pointer", onClick: function () {
                    dispatch((0, checkout_1.setSelectedBranch)(null));
                    dispatch((0, checkout_1.setSelectedAddress)({
                        full_address: address.full_address,
                        longitude: address.longitude,
                        latitude: address.latitude,
                    }));
                } },
                react_1.default.createElement("div", { className: "flex flex-row items-center" },
                    react_1.default.createElement("div", { className: "flex flex-col flex-1" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: address.label, className: "cursor-pointer" //
                            , textColor: "text-black" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: address.full_address, className: "cursor-pointer" //
                            , textColor: "text-black", size: "xsmall" })),
                    address.full_address == (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.full_address) && (react_1.default.createElement("div", { className: "rounded-lg bg-primary px-2 py-1 flex flex-row items-center" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "SELECTED", className: "cursor-pointer" //
                            , textColor: "text-white", size: "xsmall" }))))));
        }))) : (react_1.default.createElement("div", null, isGettingUserAddressList ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "medium", progressColor: "text-primary" })) : (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "You don't have any saved addresses. Please add an address." })))))),
        react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Next", backgroundColor: "bg-primary", size: "small", loading: isAddingDeliveryAddress, onClick: function () {
                    var _a, _b, _c, _d, _e;
                    if (validateAddress()) {
                        setIsAddingDeliveryAddress(true);
                        ///Address
                        (0, google_tags_1.trackAddShippingInfo)({
                            price: basket === null || basket === void 0 ? void 0 : basket.outstanding_amount,
                            products: basket === null || basket === void 0 ? void 0 : basket.product_list,
                            _id: basket._id,
                        });
                        if (saveAddress) {
                            (0, add_user_address_1.add_user_address)({
                                label: newAddressLabel,
                                full_address: selectedPlaceDetails.fullAddress,
                                latitude: (_b = (_a = selectedPlaceDetails === null || selectedPlaceDetails === void 0 ? void 0 : selectedPlaceDetails.coordinates) === null || _a === void 0 ? void 0 : _a.lat) === null || _b === void 0 ? void 0 : _b.toString(),
                                longitude: (_d = (_c = selectedPlaceDetails === null || selectedPlaceDetails === void 0 ? void 0 : selectedPlaceDetails.coordinates) === null || _c === void 0 ? void 0 : _c.lng) === null || _d === void 0 ? void 0 : _d.toString(),
                            }).then(function (response) {
                                setAddressList(__spreadArray([], addressList, true));
                            });
                        }
                        (0, update_delivery_data_1.update_delivery_data)({
                            _id: (_e = basket === null || basket === void 0 ? void 0 : basket._id) !== null && _e !== void 0 ? _e : "",
                            basket: {
                                type: "delivery",
                                address: selectedAddress !== null && selectedAddress !== void 0 ? selectedAddress : undefined,
                                instructions: "N/A", //specialInstructions, //This is only set AFTER the address is selected.
                            },
                        }).then(function (response) {
                            if (response.success) {
                                dispatch((0, basket_1.get_basket_thunk)());
                                onNext();
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({ title: "Address selection needed!", messages: [response.message] }));
                            }
                            setIsAddingDeliveryAddress(false);
                        });
                    }
                } }),
            isAddingAddress ? (react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Show address list", size: "small", onClick: function () {
                    dispatch((0, checkout_1.setSelectedAddress)(null));
                    setIsAddingAddress(false);
                } })) : (react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Add address", size: "small", onClick: function () {
                    dispatch((0, checkout_1.setSelectedAddress)(null));
                    setIsAddingAddress(true);
                } })),
            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Back", size: "small", onClick: function () {
                    onPrevious();
                } }))));
};
exports.SelectAddressStep = SelectAddressStep;
